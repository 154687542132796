
import { React, useState, useEffect } from "react";
import "./FAQ.css";
import { useAppContext } from "../lib/contextLib";
import Logo from '../images/-logos_blue.png';  //  src/images
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import { Accordion } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function FAQ() {
  let history = useHistory();
    const { setcurrentOrgLogo } = useAppContext();
    const [faqs, setFaqs] = useState([]);
    setcurrentOrgLogo(Logo);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        async function onLoad() {

            try {
                var faqs = await loadFAQs();
                setFaqs(faqs);
                setIsLoading(false);
                console.log(faqs);

            } catch (e) {
                onError("homeonl" + e);
            }


            return;

        }

        onLoad();
    }, []);

    function loadFAQs() {
        return API.get("mygiving", "/site/FAQ");
    }


/*
    const DisplayFAQ1 = () => (
        <div>
            {faqs.Questions.map((item,index) => {
                return (
                    <>
                        <div className="border">
                        <h3>{item.substr(2)}</h3>
                        <h6>{faqs.Answers[index].substr(2)}</h6>
                        </div>
                    </>
                )

            })}
        </div>

    );

    function AccordionDemo() {
        return (
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Accordion Item #1
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Accordion Item #2
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Accordion Item #3
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                  </div>
                </div>
              </div>
            </div>
        )
    }
    */
 
    const DisplayFAQ = () => (
        <div>
            {faqs.Questions.map((item,index) => {
                return (
                    <>
                        <Accordion className="Accord text-sm-start" >
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header className="Accord h5 text-start">{item.split("#")[1]}</Accordion.Header>
                                <Accordion.Body>
                                {faqs.Answers[index].split("#")[1]}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                         
                    </>
                )

            })}
        </div>

    );

 
    return (
        <div className="FAQ">
            <div className="lander">
                <section className="bg-dark text-white sectionpad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg sectionpad2  ">
                                <h2 className="">Frequently Asked Questions</h2><br />
                                If you still have questions, please reach us out at hello@mygiving.io
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light sectionpad">
                    <div className="text-left  ">
                        <br />

                        {!isLoading ? <DisplayFAQ /> : null}


                    </div>
                </section >


            </div >
            <Button className="btn-sm" onClick={() => history.goBack()}>Back</Button>

        </div>
    );
}