import React from "react";
import "./SuperUser.css"; 

export default function SuperUser(){

    return (
        <h1>
          ****Power user Logged In****
        </h1>
      );
}