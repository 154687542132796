
import { Button, Card, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
//import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import "./Home.css";
import flowChart from '../images/flowChart.jpg';  //  src/images
import nftSearch from '../images/nftSearch.jpg';  //  src/images
import cryptoEngagement from '../images/cryptoEngagement.jpg';  //  src/images
import cryptoDonation from "../images/cryptoDonation.jpg"
import introVideo from '../videos/mygivingIntro.mp4';
import mygivingVideoPoster from '../images/mygivingVideoPoster.png'
import { PopupButton } from "react-calendly";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FacebookIcon, LinkedinIcon, TwitterIcon, EmailIcon } from "react-share";

export default function Home() {

  const [orgs, setorgs] = useState([]);

  //const { userOrg } = useAppContext();


  const [isLoading, setIsLoading] = useState(true);
  //console.log("ORGGGGGGG", userOrg);
  useEffect(() => {

    async function onLoad() {

      try {
        var orgs = await loadOrgs();
        //console.log("org" + orgs);
        for (var i = 0; i <= orgs.length - 1; i++) {
          if (orgs[i].imageURL) {
            //nfts[i].imageURL = await Storage.vault.get(orgs[i].imageURL);
          }

        }
        //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
        setorgs(orgs);

      } catch (e) {
        onError("homeonl" + e);
      }

      setIsLoading(false);
      return;

    }

    onLoad();
  }, []);


  function loadOrgs() {
    return API.get("mygiving", "/organizations");
  }




  function renderOrgList(orgs) {
    return (
      <>


        <section className="bg-light">
          <h3 className="fw-bold"> Explore our collections </h3>
          <br />
          <Row xs={1}   className="g-4 mx-auto justify-content-md-center">
            {orgs.map(({ sk, orgName, orgBannerImage }) => (
              <Col key={sk}>
                <Card className="HomeOrgListItem-main-card mx-auto"  >
                  <Card.Body>
                    <div className="imageHolder mx-auto">
                      <Card.Img className="HomeOrgListItem-img mx-auto justify-content-md-center" src={orgBannerImage} />
                    </div>
                    <Card.Title className="wrapper text-wrap fw-bold">{orgName}</Card.Title>
                  </Card.Body>
                  <Card.Footer className="mx-auto justify-content-md-center justify-content-center">
                    <Link to={`/org/${sk.split("#")[1]}`}>
                      <Button variant="primary">Browse</Button>
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <section className="bg-light sectionpad">
          <div className="container">
            <div className="row">
              <div className="col-lg sectionpad2 ">
                <h2 className="fw-bold">Gamify Philanthropy with NFTs</h2><br />
                MyGiving gamifies philanthropy by empowering nonprofits to create and send NFTs and digital assets while providing a way for donors
                to virtually share and celebrate the organizations they support.
                <div className="pt-5 mb-4">
                  <PopupButton className="btn btn-primary" text="Schedule a demo to hear more!" url="https://calendly.com/mygiving2/30min" ></PopupButton>
                </div>
                <div className="mt-3">
                  <Link to={`/walletboard`}>
                    <h6>Leaderboard</h6>
                  </Link>
                </div>
              </div>
              <div className="col-lg sectionpad2 hght">
                <div className='player-wrapper'>

                  <video className="react-player" controls poster={mygivingVideoPoster}>
                    <source src={introVideo} type="video/mp4" auto />
                    Your browser does not support the video tag.
                  </video>
                  <div className="mt-1">
                    <Link to={`/faq-page`}>
                      <h6>FAQ</h6>
                    </Link>
                  </div>
                </div>

              </div>

            </div>


          </div>
        </section>
        <section className="sectionpad">
          <div className="container">

            <CarouselProvider
              naturalSlideWidth={20}
              naturalSlideHeight={10}
              totalSlides={4}
              interval={5000}
              isPlaying={true}
              isIntrinsicHeight={true}

            >
              <Slider>
                <Slide index={0}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <img src={flowChart} className="img-fluid" alt="flowchart"></img>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h2 className="fw-bold">Seamless Integration</h2><br />
                      Integrate our NFT As a Service cloud platform easily into your organization with no coding. You can start minting and transfering NFTs with a few button clicks.
                    </div>
                  </div>
                </Slide>
                <Slide index={1}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <img src={nftSearch} className="img-fluid" alt="nftSearch"></img>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">NFT Excitement</h3><br />
                      The excitement around non-fungible tokens (NFTs) is showing no signs of slowing down as the space hits new milestones and all-time highs. According to Google Search volumes, 'NFT' search volume has hit an all-time high, up by 25x since January 2021.
                    </div>
                  </div>
                </Slide>
                <Slide index={2}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <img src={cryptoEngagement} className="img-fluid" alt="cryptoEngagement"></img>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">Cryptocurrency Engagement</h3><br />
                      Thirteen percent of Americans bought or traded cryptocurrency in the past 12 months, according to survey published by NORC, a research group at the University of Chicago.
                    </div>

                  </div>
                </Slide>
                <Slide index={3}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <img src={cryptoDonation} className="img-fluid" alt="cryptoDonation"></img>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">Cryptocurrency investors are charitably inclined</h3><br />
                      A third of cryptocurrency investors have donated some of their cryptocurrency to charity- and their motivations are both head-based and heart-based
                    </div>

                  </div>
                </Slide>
              </Slider>
              <ButtonBack className="btn-primary mr-5">{"<"}</ButtonBack>
              <ButtonNext className="btn-primary ml-5">{">"}</ButtonNext>

            </CarouselProvider>
          </div>



        </section>

        <section className="bg-light  sectionpad ">
          {!isLoading && renderOrgList(orgs)}
        </section>

        <section className="  sectionpad">
          <div>
            <h3 className="fw-bold"> What we do?</h3>
            <br />
            <div className="row justify-content-md-center">


              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>NFT/FT Platform</h6>
                <p className="text-muted">One stop shop to create, manage and transfer your tokens</p>
              </div>

              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>NFT/FT Structuring</h6>
                <p className="text-muted">20+ years of fund raising experience to structure your NFT token tiers</p>
              </div>

              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>NFT/FT creation</h6>
                <p className="text-muted">Excellent artists to help you with the creation of your token images</p>
              </div>

              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>Blockchain Fundamentals</h6>
                <p className="text-muted">Blockchain education from wallets to chains to Extensions</p>
              </div>
            </div>

          </div>
        </section >

        {footerSec()}

      </div >
    );
  }

  function footerSec() {
    return (
      <section className="bg-light">
        <div className="py-5  ">
          <div className="container">
            <div className="row row-cols-auto">
              <div className="col">
                <Link to={`/faq-page`}>
                  <h5>FAQ</h5>
                </Link>
                <ul className="contact-info list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/resources`}>
                  <h5>Resources</h5>
                </Link>
                <ul className="links list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/legal`}>
                  <h5>Legal</h5>
                </Link>
                <ul className="links list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/contactus`}>
                  <h5>Contact Us</h5>
                </Link>

                <ul className="links list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/aboutus`}>
                  <h5>About Us</h5>
                </Link>

                <ul className="links list-unstyled">

                </ul>
              </div>
            </div>
            <div class="container">
              <div class="row row-cols-auto">
                <div class="col"><a href="https://www.facebook.com/mygiving.io" rel="noopener noreferrer" target="_blank" ><FacebookIcon round size={32} /></a></div>
                <div class="col"><a href="https://twitter.com/MyGivingio" rel="noopener noreferrer" target="_blank"><TwitterIcon round size={32} /></a></div>
                <div class="col"><a href="https://www.linkedin.com/company/80964223" rel="noopener noreferrer" target="_blank" ><LinkedinIcon round size={32} /></a></div>
                <div class="col"><a href="mailto:hello@mygiving.io"><EmailIcon round size={32} /></a></div>
              </div>
            </div>


          </div>
        </div>
        <div className="py-3 bg-dark text-white">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-7 text-center  ">
                <p className="mb-md-0">(C) 2021 mygiving Inc. All rights reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }



  return (
    <div className="Home">

      {renderLander()}
    </div>
  );
}