import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { useParams } from "react-router-dom";
import "./App.css";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./lib/contextLib";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Logo from './images/-logos_blue.png';  //  src/images
import ErrorBoundary from "./components/ErrorBoundary";
import Footer from "./components/Footer";
import { IoHome, IoLogIn, IoLogOut, IoSettings, IoWallet } from 'react-icons/io5';
import { IconContext } from 'react-icons';



function App() {
  const history = useHistory();
  const { id } = useParams();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userOrg, setUserOrg] = useState([]);
  const [currentOrgLogo, setcurrentOrgLogo] = useState();
  const [currentOrgObj, setcurrentOrgObj] = useState([]);

  //console.log("currentOrgLogo:"+currentOrgLogo);
  useEffect(() => {

    async function onLoad() {
      //console.log("orgPath after effect"+id);
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);

        try {
          var userInfo = await Auth.currentUserInfo();

        } catch (e) {
          //console.log("app1" + e);
        }

        //console.log("USER IFNO:", userInfo);
        var orgName = userInfo.attributes['custom:orgDomain'];
        var Orgs = await API.get("mygiving", `/domain/${orgName}`);
        //console.log(Orgs);
        //console.log("About to push");
        //history.push("/"+Orgs[0].orgPath);
        setUserOrg(Orgs[0].orgPath);
        //console.log("from App", userOrg);
        //console.log("currentOrg->"+currentOrgLogo);

      }
      catch (e) {
        if (e !== 'No current user') {
          //alert("app" + e);          
          console.log(e);
          await Auth.signOut();
          setcurrentOrgLogo(Logo);
        }
      }

      setIsAuthenticating(false);
    }
    onLoad();
  }, [userOrg, currentOrgLogo, id]);

  //setcurrentOrgLogo("https://devnfts.mygiving.io/public/mygiving-logos_white.png");



  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    setcurrentOrgLogo(Logo);
    history.push("/");
  }
  return (
    !isAuthenticating && (
      <div className=" App container-fluid py-3">
        <Navbar collapseOnSelect expand="md" className="px-3 mb-3 color-nav fixed-top  shadow-sm">
          {isAuthenticated ? (
            <>
              <LinkContainer to={ `/org/${userOrg}/admin`}>
                <Navbar.Brand className="fw-bold text-muted">
                  <img src={currentOrgLogo} alt="" height={50} />
                  <span className="font-color"></span>
                </Navbar.Brand>
              </LinkContainer>
            </>) :
            (
              <>
                <LinkContainer to={"/"}>
                  <Navbar.Brand className="fw-bold text-muted">
                    {(window.location.pathname.split("/")[1].length === 0 || window.location.pathname.split("/")[1] === "wallets") ? (<img src={Logo} alt="" height={50} />) : (<img src={currentOrgLogo} alt="" height={50} />)}

                    <span className="font-color"></span>
                  </Navbar.Brand>
                </LinkContainer>
              </>

            )



          }


          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                  <LinkContainer to={ `/org/${userOrg}/admin`}>
                    <Nav.Link><span className="font-color" >
                      <IconContext.Provider value={{ color: currentOrgObj.orgColorTheme, className: "global-class-name", size: "2em" }}>
                        <div title="Home" className="pl-3">
                          <IoHome />
                        </div>
                      </IconContext.Provider>
                    </span></Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={ `/org/${userOrg}/settings`}>
                    <Nav.Link><span className="font-color" >
                      <IconContext.Provider value={{ color: currentOrgObj.orgColorTheme, className: "global-class-name", size: "2em" }}>
                        <div title="Settings" className="pl-3">
                          <IoSettings />
                        </div>
                      </IconContext.Provider>
                    </span></Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={handleLogout}><span className="font-color" >
                    <IconContext.Provider value={{ color: currentOrgObj.orgColorTheme, className: "global-class-name", size: "2em" }}>
                      <div title="Logout" className="pl-3">
                        <IoLogOut />
                      </div>
                    </IconContext.Provider>
                  </span></Nav.Link>
                </>

              ) : (
                <>
                  {/*<LinkContainer to="/signup">
                  <Nav.Link><span className="font-color" >Signup</span></Nav.Link>
            </LinkContainer>*/}
                  <LinkContainer to="/walletboard">
                    <Nav.Link>
                      <IconContext.Provider value={{ color: "#007bff", className: "global-class-name", size: "3em" }}>
                        <div title="Wallet">
                          <IoWallet />
                        </div>
                      </IconContext.Provider>
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>
                      <IconContext.Provider value={{ color: "#007bff", className: "global-class-name", size: "3em" }}>
                        <div title="Login">
                          <IoLogIn />
                        </div>
                      </IconContext.Provider>
                    </Nav.Link>
                  </LinkContainer>

                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, userOrg, setUserOrg, currentOrgLogo, setcurrentOrgLogo, currentOrgObj, setcurrentOrgObj }}>
            <Routes />
            <Footer />
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;


