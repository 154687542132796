import React from "react";
import "./Footer.css";
import Logo from '../images/-logos_blue.png'; 

export default function Footer() {

    return (
        <div className=" "> 
             <hr/>
             <p className="float-end small  font-weight-light">Powered by <a href="/"> <img src={Logo} alt="" height={30} /> </a></p>
        </div>
    );
}