
import React from "react";
import "./AboutUs.css";
import { useAppContext } from "../lib/contextLib";
import { Card, Row, Col,Button } from "react-bootstrap";
import Logo from '../images/-logos_blue.png';  //  src/images
import Karthik from '../images/karthik.jpg';  //  src/images
import Stephen from '../images/Stephen.jpg';  //  src/images
import { useHistory } from "react-router-dom";
//import acceptInstructions from "https://demonfts.mygiving.io/public/How_to_accept_your_NFT_or_digital_asset_from_MyGiving.pdf";

export default function AboutUs() {
    let history = useHistory();
    const { setcurrentOrgLogo } = useAppContext();
    setcurrentOrgLogo(Logo);

    function renderLander() {
        return (
            <div className="lander">
                <section className="bg-dark text-white sectionpad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg sectionpad2  ">
                                <h2 className="">About Us</h2><br />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light sectionpad">
                    <div>
                        <br />
                        <h3>What do you get when you add years & years of  Development and Software Development experience? </h3>
                        <h2 className="mt-3 mb-4">You get <a href="/">MyGiving!!</a> </h2>
                        <div className="mt-3">
                            <Row xs={1}  className="g-4">
                                <Col>
                                    <Card border="info" style={{ width: '20rem' }}>
                                        <Card.Img variant="top" src={Karthik}/>
                                        <Card.Body>
                                            <Card.Title className="fw-bold">Karthik Shanmugam</Card.Title>
                                            <Card.Text>
                                             19 years of experience in building enterprise cloud applications at elite educational and  financial institutions. Managed development teams and built complex technical solutions for various business needs.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card border="info" style={{ width: '20rem' }}>
                                        <Card.Img variant="top" src={Stephen} />
                                        <Card.Body>
                                            <Card.Title className="fw-bold">Stephen Rodriguez</Card.Title>
                                            <Card.Text>
                                            15+ years of fundraising and development experience at elite educational institutions. Managed teams that included direct marketing, major gifts, leadership gifts, raising over $100 million.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        </div>

                    </div>
                </section >


            </div >
        );
    }




    return (
        <div className="Resources">

            {renderLander()}
            <Button className="btn-sm" onClick={() => history.goBack()}>Back</Button>
        </div>
    );
}