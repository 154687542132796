import "./ListNFTTransfers.css";
import { Table } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { Link, useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";




export default function ListNFTTransfers() {

    const { id, nft } = useParams();
    const [nftAddresses, setnftAddresses] = useState([]);
    //const [displayInfo, setdisplayInfo] = useState(false);
    //const [dispMsg, setdispMsg] = useState([]);

    //const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function onLoad() {

            try {
                var nftAddresses = await API.get("mygiving", `/organization/${id}/NFTS/${nft}/listAddresses`);
                setnftAddresses(nftAddresses);

            } catch (e) {
                onError(e);
            }

            // setIsLoading(false);

        }
        onLoad();
    }, [id, nft]);

    /*   const DisplayInfo = () => (
           <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
               <span>{dispMsg}</span>
           </div>
       );
   
       */
    /*  const onClick = async () => {
          if (typeof window.AlgoSigner !== 'undefined') {
              setdisplayInfo(false);
              setIsLoading(true);
  
          } else {
              setdispMsg("No wallet found");
              setdisplayInfo(true);
          };
      }
  */
    /*const sendNow = async (e) => {
        const addresses = e.target.getAttribute('data-item');
        console.log(addresses);
        if (typeof window.AlgoSigner !== 'undefined') {
            //setdisplayInfo(false);
            // setIsLoading(true);
            transferNFT(addresses.assetID, addresses.walletAddress);
        } else {
            //setdispMsg("No wallet found");
            //setdisplayInfo(true);
        };
    }
*/


    /*   async function transferNFT(assetID, walletAddress) {
   
           var assetIDInt = parseInt(assetID);
           try {
               await window.AlgoSigner.connect({
                   ledger: 'TestNet'
               });
               const accts = await window.AlgoSigner.accounts({
                   ledger: 'TestNet'
               });
               const txParams = await window.AlgoSigner.algod({
                   ledger: 'TestNet',
                   path: '/v2/transactions/params'
               });
               const signedTx = await window.AlgoSigner.sign({
                   assetIndex: assetIDInt,
                   from: accts[0]['address'],
                   to: walletAddress,
                   amount: 1,
                   type: 'axfer',
                   fee: txParams['min-fee'],
                   firstRound: txParams['last-round'],
                   lastRound: txParams['last-round'] + 1000,
                   genesisID: txParams['genesis-id'],
                   genesisHash: txParams['genesis-hash'],
                   flatFee: true
               })
   
               const r = await window.AlgoSigner.send({
                   ledger: 'TestNet',
                   tx: signedTx.blob
               })
               console.log(r);
   
   
           }
           catch (e) {
               alert(JSON.stringify(e, null, 2));
               console.log(JSON.stringify(e, null, 2));
               //console.log(JSON.stringify(signedTx, null, 2));
           }
   
       }
   
   */

    /*
  {nftAddresses.map((addresses, index)  => { return(

      <tr key={index} data-item={addresses} onClick={sendNow}>



          <td>
              {addresses.pk.split("#")[1]}
          </td>
          <td>
              {addresses.claimed}
          </td>
          <td>
              {addresses.emailSent}
          </td>
          <td>
              {addresses.createdBy}
          </td>
          <td>
              {addresses.createdAt}
          </td>
      </tr>);
})}
*/

    function rendernftAddresses(nftAddresses) {
        return (
            <>
                <h3 className="mt-4">Transfer History</h3>
                <Table bordered responsive="sm" className="tableDiv mt-3">
                    <thead>
                        <tr>
                            <th>Email Address</th>
                            <th>Transfered?</th>
                            <th>Transfered Date</th>
                            <th>Initiated By</th>
                            <th>Initiated Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {nftAddresses.map((addresses, index) => {
                            return (

                                <tr key={index} data-item={addresses}  >
                                    <td>
                                        {addresses.pk.split("#")[1]}
                                    </td>
                                    <td>
                                        {addresses.transfered}
                                    </td>
                                    <td>
                                        {addresses.transferedDate > 0 ? new Date(addresses.transferedDate).toLocaleDateString() + " " + new Date(addresses.transferedDate).toLocaleTimeString() : ""}
                                    </td>
                                    <td>
                                        {addresses.createdBy}
                                    </td>
                                    <td>
                                        {addresses.createdAt > 0 ? new Date(addresses.createdAt).toLocaleDateString() + " " + new Date(addresses.createdAt).toLocaleTimeString() : ""}
                                    </td>
                                </tr>);
                        })}
                    </tbody>

                </Table>
                <div className="mt-3">
                    <Link to={`/org/${id}/admin/nfts/${nft}`}>
                        Back
                    </Link>
                </div>


            </>


        );
    }




    return (
        <div className="Home">

            {rendernftAddresses(nftAddresses)}
        </div>
    );
}